<template>
<div class="container">
    <div class="detail"  v-html="detail"></div>
</div>
</template>

<script>
import Core from '@/core';

export default {
    data() {
        return {
            id: '',
            detail: {},
        }
    },
    mounted() {
        this.id = this.$route.query.id;
        this.getDetail()
    },
    methods: {
        getDetail() {
            Core.Api.Course.detail(
                this.id,
            ).then(res => {
                console.log('getCourseDetail res:', res)
                this.detail = res.detail.detail
                console.log('detail:', this.detail)
            })
        }
    }
}
</script>

<style scoped>
.container {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}
</style>